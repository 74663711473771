import { render, staticRenderFns } from "./Tracce.vue?vue&type=template&id=dfa68686&scoped=true&"
import script from "./Tracce.vue?vue&type=script&lang=js&"
export * from "./Tracce.vue?vue&type=script&lang=js&"
import style0 from "./Tracce.vue?vue&type=style&index=0&id=dfa68686&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dfa68686",
  null
  
)

export default component.exports