<template>
  <div>
    <TosListVuetify entityName="tracce" :headers="headers">

      <template #item.periodicita="slotProps">
        <div v-html="formatPeriodicita(slotProps.data.periodicita)"></div>
      </template>
      <template #item.oraArrivo="slotProps">
        {{slotProps.data.oraArrivo | formatTimeFromDate}} <b>{{getOraArrivoPiuUno(slotProps.data.oraPartenza, slotProps.data.oraArrivo, slotProps.data.isExport)}}</b>
      </template>
      <template #item.oraPartenza="slotProps">
        {{slotProps.data.oraPartenza | formatTimeFromDate}} <b>{{getOraPartenzaMenoUno(slotProps.data.oraPartenza, slotProps.data.oraArrivo, slotProps.data.isExport)}}</b>
      </template>
      <template #item.fineValidita="slotProps">
        <span v-if="!slotProps.data.inizioValidita && !slotProps.data.fineValidita">
          ND  
        </span>
        <span v-else>
          <span v-if="slotProps.data.inizioValidita">
            {{ slotProps.data.inizioValidita | formatShortDateFormat }} 
          </span>
          <span v-else> / </span>
          - 
          <span v-if="slotProps.data.fineValidita">
            {{ slotProps.data.fineValidita | formatShortDateFormat }}
          </span>
          <span v-else> / </span>
        </span>
      </template>

      <template #customButtons="slotProps">
        <v-btn icon title="Tracce collegate"  @click="showTracceCollegate(slotProps.data.id)">
          <v-icon color="primary" medium> mdi-link-variant</v-icon>
        </v-btn>
      </template>

      <template #customFilters="slotProps">
        <v-container fluid>
          <v-row>
            <v-col cols="3">
                <v-text-field :label="tLabel('numero_traccia')"  v-model="slotProps.data.codice" clearable/>
              </v-col>
            <v-col cols="3">
                <v-select  :label="tLabel('nodo_partenza')" v-model.number="slotProps.data.nodoPartenzaId"
                  :items="listNodi" item-text="codice" item-value="id"  clearable/>
              </v-col>
              <v-col cols="3">
                <v-select  :label="tLabel('nodo_arrivo')" v-model.number="slotProps.data.nodoArrivoId"
                  :items="listNodi" item-text="codice" item-value="id"  clearable/>
              </v-col>
          </v-row>
        </v-container>
      </template>

      <template #editF="slotProps">

        <EditTracciaVuetify v-if="slotProps"
          :slotProps="slotProps" 
          :listNodi="listNodi"
          @itemUpdated="itemUp => item = itemUp"  
        />

      </template>

    </TosListVuetify>

    <div class="content-section implementation">
      
      <v-dialog 
        v-model="relatedsDialog" max-width="840" width="840" scrollable
        header="Tracce legate" 
      >
        <div style="width:800px;overflow: auto">
          <div style="width:1200px">
            <v-data-table :items="legamentiTracce" :headers="legamentiHeaders" >
                <template #item.tracciaExport="{ item }">
                  <span v-if="item.tracciaExportId">
                    <a 
                      v-if="item.tracciaExport.id !== legamentiTracciaId" 
                      href="#"
                      v-on:click="filtroPerCodice(item.tracciaExport.codice)"
                    >{{ item.tracciaExport.nodoArrivo.codice }}</a>
                    <span v-else>{{ item.tracciaExport.nodoArrivo.codice }}</span>                    
                  </span>
                </template>

                <template #item.tracciaImport="{ item }">
                  <span v-if="item.tracciaImportId">
                    <a 
                      v-if="item.tracciaImport.id !== legamentiTracciaId" 
                      href="#"
                      v-on:click="filtroPerCodice(item.tracciaImport.codice)"
                    >{{ item.tracciaImport.nodoArrivo.codice }}</a>
                    <span v-else>{{ item.tracciaImport.nodoArrivo.codice }}</span>                    
                  </span>
                </template>
            </v-data-table>
          </div>
        </div>
      </v-dialog>    
    </div>
  </div>
</template>

<script>
import TosListVuetify from '../../components/anagrafiche/TosListVuetify';
import EditTracciaVuetify from './EditTracciaVuetify';
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Tracce",
  components: {
    EditTracciaVuetify, TosListVuetify
  },
  mixins: [FormatsMixins],
  data() {
    return {
      legamentiTracce: [],
      legamentiTracciaId: null,
      listNodi: [],
      relatedsDialog: false,
      headers: [
         { text: '', value: 'action' },
         { text: 'Numero traccia', value: 'codice' },
         { text: 'Vett. ferroviario', value: 'vettore' },
         { text: 'Nodo partenza', value: 'nodoPartenza' },
         { text: 'Nodo arrivo', value: 'nodoArrivo' },
         { text: 'Periodicita', value: 'periodicita'},
         { text: 'ETD', value: 'oraPartenza', formatValue:"formatTimeFromDate" },
         { text: 'ETA', value: 'oraArrivo',  formatValue:"formatTimeFromDate" },
         { text: 'MP', value: 'mercePericolosa', formatValue:'formatBoolean'  },
         { text: 'TES', value: 'fuoriSagoma', formatValue:'formatBoolean'  },
         { text: 'Validità', value: 'fineValidita' }
      ],
      legamentiHeaders: [
         { text: 'Arrivo', value: 'tracciaExport' },
         { text: 'Partenza', value: 'tracciaImport' },
         { text: 'Giorno arrivo', value: 'giornoArrivo' },
         { text: 'Giorno partenza', value: 'giornoPartenza' },
         { text: 'ETA', value: 'tracciaExport.oraArrivo ' },
         { text: 'Ora partenza', value: 'tracciaImport.oraPartenza' },

      ],
    };
  },
  props: { 
  },
  async mounted() {
    this.listNodi=(await this.$api.get(this.$apiConfiguration.BASE_PATH+"nodi/list")).data.sort((a, b) => (a.codice > b.codice ? 1 : -1));
  },
  methods: {
    async showTracceCollegate(idTraccia) {
      try {
        this.loading = true;
        this.legamentiTracciaId = idTraccia;
        this.legamentiTracce = await this.$api.trainData.getLegamentiTracce(idTraccia);
        this.loading = false;
        this.relatedsDialog = true;
      } catch(e) {
        console.log(e);
      }
    },
    filtroPerCodice(codice) {
      this.$emit('filterTable',  { codice: codice } );
      this.relatedsDialog = false;
    },
    validate(item){
      let errors = '';
      if(!item.nodoPartenza) {
        errors += 'nodo partenza non selezionato';
      }
      if(!item.nodoArrivo) {
        errors += 'nodo arrivo non selezionato';
      }
      if(item.listLegamentiImport){
        if(item.listLegamentiImport
          .some(l => !l.giornoArrivo || !l.giornoPartenza)){
          errors += 'selezionare giorni di partenza e arrivo nei legamenti di partenza';
        }
      }
      if(item.listLegamentiExport){
        if(item.listLegamentiExport
          .some(l => !l.giornoArrivo || !l.giornoPartenza)){
          errors += 'selezionare giorni di partenza e arrivo nei legamenti di arrivo';
        }
      }      
      return errors;
    },
    getOraArrivoPiuUno(oraPartenza, oraArrivo, isArrivo) {
      if ((oraArrivo < oraPartenza) && !isArrivo) {
        return "(+1)";
      }
      return "";
    },
    getOraPartenzaMenoUno(oraPartenza, oraArrivo, isArrivo) {
      if ((oraArrivo < oraPartenza) && isArrivo) {
        return "(-1)";
      }
      return "";
    },
    // async saveItem() {
    //   try {
    //     const errorMsg = this.validate();
    //     if (errorMsg) {
    //       this.showError(errorMsg);
    //       return;
    //     }
    //     this.editResponse = null;
    //     const data = await this.service.edit(this.item, "/edit");
    //     this.dialogVisible = false;
    //     this.item = data;
    //     let index = this.findIndexById(this.item.id);
    //     let items = [...this.items];
    //     if (index === -1) {
    //       items.push(this.item);
    //     } else {
    //       items[index] = this.item;
    //     }
    //     this.items = items;
    //     this.item = null;
    //     this.selectedItem = null;
    //     this.showSuccess('Salvataggio eseguito con successo');          
    //     this.loading = false;
    //   } catch (e) {
    //     this.editResponse = e;
    //     this.showError(e);
    //   }
    // },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .p-datatable .p-sortable-column.p {
    border: 2px solid red;
  }
  .p-datatable .p-sortable-column.p-highlight {
    border: 3px solid red;
  }
  .errorMessage {
    color: red;
  }
}
</style>
